.root {
  max-width: 360px;
  width: calc(100% - 40px);
  display: flex;
  position: fixed;
  z-index: 9999999999;
}

.notification-topRight {
  top: 24px !important;
  bottom: initial !important;
  left: initial !important;
  right: 20px;

  flex-direction: column;
}

.notification-topLeft {
  top: 24px !important;
  bottom: initial !important;
  right: initial !important;
  left: 20px;

  flex-direction: column;
}

.notification-bottomRight {
  bottom: 24px !important;
  top: initial !important;
  left: initial !important;
  right: 20px;

  flex-direction: column-reverse;
}

.notification-bottomLeft {
  bottom: 24px !important;
  top: initial !important;
  right: initial !important;
  left: 20px;

  flex-direction: column-reverse;
}

.notification-topRight > span > div,
.notification-bottomRight > span > div {
  animation: notification-right-in 200ms ease-in 1;
}

.notification-topLeft > span > div,
.notification-bottomLeft > span > div {
  animation: notification-left-in 200ms ease-in 1;
}

/* Styles the notice */
.root > span > div {
  background: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 12px;
}

.container {
  display: flex;
  flex-direction: row;
}

.icon {
  margin-right: 12px;
  font-size: 1.2rem;
}

.content {
  display: flex;
  flex-direction: column;
}

.message {
  font-size: 1rem;
}

.description {
  font-size: 0.875rem;
  margin-top: 12px;
}

@keyframes notification-left-in {
  0% {
    transform: translateX(-360px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes notification-right-in {
  0% {
    transform: translateX(360px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
