.flex {
}
.-left {
}

.table-widget {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  --primary: #f951a4;
  --accent: #192f59;
  --faint-blue: #ecf5fd;
}

.table-widget * {
  box-sizing: border-box;
}

.table-widget .ReactTable {
  height: 100%;
  width: 100%;
  border-radius: 4px;
}
.table-widget .shortcuts-container {
  height: 100%;
}
.table-widget .table-actions .transparent-button {
  color: var(--light-gray);
}
.table-widget .table-actions .transparent-button:hover {
  color: var(--blue);
}
.table-widget .ReactTable .rt-tbody {
  background: #fff;
  font-size: 12px;
  color: #312929;
  box-shadow: inset 0 -1px 0 0 #e3e3e3;
}
.table-widget .pagination-top .rt-tbody {
  box-shadow: none;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.table-widget .ReactTable .rt-tr-group {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.table-widget .ReactTable {
  height: 100%;
  border-radius: 4px;
  border: none;
}
.table-widget .ReactTable .rt-table {
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  will-change: transform;
}
.table-widget .ReactTable .ReactTable .-pagination .-pageInfo {
  margin: 0;
}
.table-widget .rt-resizer {
  z-index: 1000;
  width: 30px;
  right: -15px;
}
.table-widget .rt-resizable-header {
  font-weight: 600;
  display: inherit;
}
.table-widget .rt-thead {
  background: #fcfcfc;
  margin-top: -1px;
  border-bottom: 1px solid #e3e3e3;
}
.table-widget .ReactTable .-pagination .-pageJump input {
  font-family: var(--sans-serif);
}
.table-widget .ReactTable .-pagination .-currentPage {
  font-family: var(--sans-serif);
  margin-left: 5px;
}
.table-widget input::-webkit-inner-spin-button,
.table-widget input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.table-widget .rt-thead .rt-resizable-header-content {
  width: 100%;
  font-size: 11px;
  padding-left: 6px;
  text-align: left;
}
.table-widget .rt-thead .rt-resizable-header-content > div {
  overflow: hidden;
  text-overflow: unset;
}
.table-widget .rt-column-header-name {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.table-widget .rt-column-header-name-icon-override {
  width: calc(100% - 18px);
}
.table-widget .ReactTable .rt-thead .rt-td,
.table-widget .ReactTable .rt-thead .rt-th {
  padding: 10px 5px;
  border: none;
}
.table-widget .ReactTable .rt-thead .rt-td:after,
.table-widget .ReactTable .rt-thead .rt-th:after {
  position: absolute;
  top: 0;
  height: 42px;
  right: 0;
  border-right: 1px solid #e3e3e3;
  content: "";
}
.table-widget .ReactTable .rt-thead .rt-td:last-child:after,
.table-widget .ReactTable .rt-thead .rt-th:last-child:after {
  border-right: none;
}
.table-widget .ReactTable .rt-thead .rt-td.-sort-asc,
.table-widget .ReactTable .rt-thead .rt-th.-sort-asc {
  box-shadow: inset 0 2px 0 0 var(--light-gray);
  background: var(--faint-gray);
}
.table-widget .ReactTable .rt-tbody .rt-td,
.table-widget .ReactTable .rt-thead .rt-td,
.table-widget .ReactTable .rt-thead .rt-th:last-child,
.table-widget .rt-tr-group .rt-td:last-child .cell-container {
  border-right: none;
}
.table-widget .ReactTable .-pagination {
  -ms-flex: 1;
  flex: 1;
  text-align: center;
  box-shadow: none;
  -ms-flex-align: center;
  align-items: center;
  padding: 2px 12px;
  color: rgba(0, 0, 0, 0.6);
  background: #fcfcfc;
  border-bottom-width: 1px;
  border-top-width: 0;
  border-bottom-color: transparent;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.table-widget .pagination-bottom,
.table-widget .pagination-top {
  position: relative;
}
.table-widget .pagination-top .-pagination {
  border-top-width: 0;
  border-radius: 4px;
  box-shadow: inset 0 -1px 0 0 var(--faint-gray);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.table-widget .table-header {
  background-color: #fff;
  font-weight: 600;
}
.table-widget .table-cell-opacity {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.table-widget .cell-container {
  position: relative;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px solid #e3e3e3;
}
.table-widget .cell-container-editable:hover {
  text-decoration: underline;
  cursor: text;
}
.table-widget .inner-cell-container {
  padding: 0 11px;
  overflow: hidden;
}
.table-widget .inner-cell-container--wrap {
  white-space: normal;
}
.table-widget .inner-cell-container--number {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.table-widget .inner-cell-container--overflown {
  margin-right: 30px;
}
.table-widget .table-cell-action {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}
.table-widget .table-cell-action-left {
  border-right: 1px solid #e3e3e3;
}
.table-widget .table-cell-action--compact button {
  height: 18px;
}
.table-widget .expand-cell-button {
  position: absolute;
  right: 5px;
  border: none;
  cursor: pointer;
  color: #0e77ca;
  font-weight: 700;
}
.table-widget .table-row {
  cursor: pointer;
  min-height: 30px;
}
.table-widget .table-row.selected,
.table-widget .table-row:hover {
  background-color: var(--faint-blue);
}
.table-widget .table-results-counter {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 26px;
  margin-right: 8px;
}
.table-widget .decode-arrow-button,
.table-widget .decode-clear-button {
  border: 1px solid #e3e3e3;
  height: 26px;
  border-radius: 14px;
  background-color: #f2f2f2;
  color: var(--blue);
}
.table-widget .decode-arrow-button {
  width: 36px;
}
.table-widget .decode-arrow-button:disabled,
.table-widget .decode-clear-button:disabled {
  color: rgba(0, 0, 0, 0.6);
}
.table-widget .null-cell {
  color: #bfbfbf;
}
.table-widget .boolean-cell-true {
  color: #00a854;
}
.table-widget .boolean-cell-false {
  color: #d73435;
}
.table-widget .-pagination.light-pagination-ui {
  border: none;
}
.ReactTable .-pagination .-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.table-widget .rt-resizable-header {
  font-weight: 600;
  display: inherit;
}
.table-widget .rt-thead {
  background: #fcfcfc;
  margin-top: -1px;
  border-bottom: 1px solid #e3e3e3;
}

.ReactTable * {
  box-sizing: border-box;
}
.ReactTable .rt-table {
  -ms-flex: auto 1;
  flex: auto 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}
.ReactTable .rt-thead {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
}
.ReactTable .rt-thead.-filters,
.ReactTable .rt-thead.-headerGroups {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: 400;
  outline: none;
}
.ReactTable .rt-thead.-filters .rt-th {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}

.ReactTable .rt-thead.-header {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
}
.ReactTable .rt-thead .rt-tr {
  text-align: center;
}
.ReactTable .rt-thead .rt-td,
.ReactTable .rt-thead .rt-th {
  padding: 5px;
  line-height: normal;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  /* transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
  box-shadow: inset 0 0 0 0 transparent;
}
.ReactTable .rt-thead .rt-td.-sort-asc,
.ReactTable .rt-thead .rt-th.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6);
}
.ReactTable .rt-thead .rt-td.-sort-desc,
.ReactTable .rt-thead .rt-th.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);
}
.ReactTable .rt-thead .rt-td.-cursor-pointer,
.ReactTable .rt-thead .rt-th.-cursor-pointer {
  cursor: pointer;
}
.ReactTable .rt-thead .rt-td:last-child,
.ReactTable .rt-thead .rt-th:last-child {
  border-right: 0;
}
.ReactTable .rt-thead .rt-th:focus {
  outline: none;
}
.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
}
.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}
.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
}
.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7;
}
.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: hsla(0, 0%, 100%, 0);
  border-left-color: #fff;
  border-width: 8px;
  margin-top: -8px;
}
.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: hsla(0, 0%, 40%, 0);
  border-left-color: #f7f7f7;
  border-width: 10px;
  margin-top: -10px;
}
.ReactTable .rt-tbody {
  -ms-flex: 99999 1 auto;
  flex: 99999 1 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
}
.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0;
}
.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}
.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0;
}
.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip;
}
.ReactTable .rt-tr-group {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.ReactTable .rt-tr {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.ReactTable .rt-td,
.ReactTable .rt-th {
  /* -ms-flex: 1 0 0px;
  flex: 1 0 0; */
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 5px;
  overflow: hidden;
}

/* .ReactTable .rt-expander:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}
.ReactTable .rt-expander.-open:after {
  transform: translate(-50%, -50%) rotate(0);
} */
.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10;
}
.ReactTable .rt-tfoot {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}
.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0;
}
.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.03);
}
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(0, 0, 0, 0.05);
}
.ReactTable .-pagination {
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}
.ReactTable .-pagination input,
.ReactTable .-pagination select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: 400;
  outline: none;
}
.ReactTable .-pagination .-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
  /* transition: all 0.1s ease; */
  cursor: pointer;
  outline: none;
}
.ReactTable .-pagination .-btn[disabled] {
  opacity: 0.5;
  cursor: default;
}
.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}
.ReactTable .-pagination .-next,
.ReactTable .-pagination .-previous {
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}
.ReactTable .-pagination .-center {
  -ms-flex: 1.5;
  flex: 1.5;
  text-align: center;
  margin-bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
}
.ReactTable .-pagination .-pageJump {
  display: inline-block;
}
.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center;
}
.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px;
}
.ReactTable .rt-noData {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -70%);
  z-index: 1;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5);
}
.ReactTable .-loading,
.ReactTable .rt-noData {
  display: block;
  position: absolute;
  background: hsla(0, 0%, 100%, 0.8);
  /* transition: all 0.3s ease; */
  pointer-events: none;
}
.ReactTable .-loading {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
}
.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  transform: translateY(-52%);
  /* transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94); */
}
.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}
.ReactTable .-loading.-active > div {
  transform: translateY(50%);
}

.decode-button.ant-btn {
  font-family: var(--sans-serif);
  height: 2rem;
  border-radius: 4px;
  border: none;
  font-size: var(--font-size-12);
  line-height: var(--line-height-solid);
  color: #fff;
  font-weight: 600;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  padding: 0 16px;
  box-shadow: none;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  text-shadow: none;
  text-align: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.decode-popover :global .rc-tooltip-arrow {
  display: none;
}

.decode-button.ant-btn-link-gray {
  color: var(--gray);
  background: var(--near-white);
  border: 1px solid transparent;
  padding: 0;
}
.decode-button.ant-btn-link {
  color: var(--blue);
  font-weight: 500;
  padding: 0;
}

.decode-button.ant-btn,
.decode-button svg {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.decode-button.ant-btn-link-gray:hover {
  color: var(--blue);
  background: var(--faint-blue);
}

.decode-button[disabled] {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: transparent !important;
  cursor: not-allowed;
}

.table-widget .null-cell {
  color: #bfbfbf;
}

.table-widget .boolean-cell-true {
  color: #00a854;
}

.table-widget .boolean-cell-false {
  color: #df000f;
}
